$height-user-notification: $one-unit * 10;

.lvl-user-notification-container {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 11;
  padding: $one-unit;
  width: 100%;
  bottom: 0;
  animation-name: un-animate-in;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(.25,.1,.25,1);

  &.is-closing {
    bottom: -$height-user-notification;
    animation-name: un-animate-out;
    animation-duration: 0.2s;
    animation-timing-function: cubic-bezier(.25,.1,.25,1);
  }
}

// TEMP - User notification animation

@keyframes un-animate-in {
  from { bottom: -$height-user-notification; }
  to { bottom: 0; }
}

@keyframes un-animate-out {
  from { bottom: 0; }
  to { bottom: -$height-user-notification; }
}

.lvl-user-notification {
  position: relative;
  // max-width: 800px; min-width: 300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ($one-unit) ($one-unit * 1.5);

  &__message {
    font-size: 150%;
    font-weight: bold;
    color: $color-primary;
    margin-right: $one-unit * 4;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 (($one-unit * 0.5) - ($one-unit * 0.25)) 0 ($one-unit * 0.5); // Subtract the border width
    background: darken($color-gray-1, 10%);

    &:after {
      content: "\f00d";
      font-family: "Font Awesome 5 Pro";
      font-size: 150%;
      font-weight: bold;
      color: $color-primary;
    }
  }


  // Variations

  // This is "default"
  background: $color-gray-1;
  border: ($one-unit * 0.25) solid darken($color-gray-1, 10%);

  &--success {
    background: $color-green;
    border: ($one-unit * 0.25) solid darken($color-green, 10%);
    .lvl-user-notification__close { background: darken($color-green, 10%); }
  }

  &--error {
    background: $color-red;
    border: ($one-unit * 0.25) solid darken($color-red, 10%);
    .lvl-user-notification__close { background: darken($color-red, 10%); }
  }

  &--warning {
    background: $color-yellow;
    border: ($one-unit * 0.25) solid darken($color-yellow, 10%);
    .lvl-user-notification__close { background: darken($color-yellow, 10%); }
  }


}

.lvl-user-message {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ($one-unit) ($one-unit * 1.5);

  &__message {
    margin-right: $one-unit * 4;

    * {
      font-size: calc(var(--one-unit) * 1.5);
      font-weight: bold;
      color: $color-primary;
    }
  }

  // Variations

  // This is "default"
  background: $color-gray-1;

  &--success {
    background: $color-green;
  }

  &--error {
    background: $color-red;
  }

  &--warning {
    background: $color-yellow;
  }


}
