.lvl-info-card {
  border-radius: 5px;
  background: #f7eefc;
  padding: $one-unit * 3;
  margin: 1vmax;
  flex: 0 1 30vmax;

  &__body {

  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid darken(#f7eefc, 20%);
    padding-top: $one-unit * 1;
    margin-bottom: -($one-unit * 2);
  }

  // Different
  &--adding-card {
    border: 2px dashed #bbb;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

}

.lvl-info-card-inner { // temp class
  background: #f7eefc;
}

// * > .lvl-info-card {
//   &:first-child {
//     margin-left: 0;
//   }

//   &:last-child {
//    margin-right: 0
//   }
// }
