$line-height: 1.4;
$font-family: proxima-nova, sans-serif;

* {
  font-size: .8vmax;
}

small {
  font-size: .7vmax; * { font-size: .7vmax; }
}

.h-text-emphasized {
 font-size: 2.4vmax;
}


@media (max-width: 500px) { // TEMP FOR PHONES
  * {
    font-size: 1.4vmax;
  }

  small {
    font-size: 1.1vmax; * { font-size: 1.1vmax; }
  }

  .h-text-emphasized {
   font-size: 2.8vmax;
  }
}

*:not(svg) {
  font-family: $font-family;
  line-height: $line-height;
}

p {
  margin-bottom: $one-unit;
  max-width: 400px; // added for readability
}

h1 {
  font-size: 200%;
  margin-bottom: $one-unit;
  font-weight: 600;
}

h2 {
  font-size: 160%;
  margin-bottom: $one-unit;
  font-weight: 600;
  > * { font-size: 100%; } // temp hack for fixing nested sizes
}

h3 {
  font-size: 130%;
  margin-bottom: $one-unit;
  font-weight: 600;
  > * { font-size: 100%; } // temp hack for fixing nested sizes
}

// Helpers
.h-uppercase {
  text-transform: uppercase;
}

.h-text-bold {
  font-weight: 600;
}
