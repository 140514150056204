.lvl-table-container {
  height: 0;
  overflow: hidden;

  &.is-open {
    height: auto;
  }

}

.lvl-table {
  width: 100%;

  tbody {
    tr {
      background-color: white;
      @include lvl-transition($property: background-color);
      &:hover {
        background-color: whitesmoke;
        @include lvl-transition($property: background-color);
      }

    }

  }

  td, th {
    padding: 0.5vmax;
    border-bottom: 1px solid #ccc;
  }

  &__inline-label {
    display: none;
  }

  // Responsive
  ////////////////////////////
  @include mq-vertical-viewport {
    thead {
      display: none;
    }

    tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 2vmax;
      padding: 1vmax;
      &:not(.heading-row) {
        border: 1px solid #ccc;
      }
    }

    td {
      display: flex;
      border-bottom: 0;

      > * { flex: 1 1 0; }

      &.h-text-align-right { // temp hack until we have better nested tables
        text-align: left;
      }

    }

    &__inline-label {
      display: block;
    }

    .heading-row { // temp hack until we have better nested tables
      .lvl-table__inline-label { display: none; }
      + tr {
        display: none;
      }
    }

  } // End vertical-viewport

}

// WIP
.lvl-temp-accordion-anchor {
  display: inline-block;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: "\f077";
    font-family: "Font Awesome 5 Pro";
    margin-left: $one-unit;
  }

  &.is-open {
    &:after {
      content: "\f078";
    }
  }
}

// Temp, styling the nested heading row
tr.heading-row {
  &:hover {
    background-color: white !important;
  }
}
td h2 {
  margin: 0; // reset
  margin-top: $one-unit * 3;
  margin-bottom: $one-unit * .5;
  margin-left: -0.5vmax; // same as td padding above here
}
