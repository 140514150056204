body, html {
  height: 100%;
}

.app {
  height: 100%;
  position: relative;

  &__header {
  }

  &__body {
    min-height: 100%;

    &.lvl-section--ws {
      display: flex;
    }
  }

}

.login-form {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

