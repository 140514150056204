//////////////////////////////////
// Mixins/Helpers
//////////////////////////////////
// Always mixin + helper
// Mixin used in scss, helper used in js ol.

@mixin h-is-clickable {
  @extend .h-is-clickable;
}

.h-is-clickable {
  &:hover { cursor: pointer; }
}

// Whitespace helpers
.lvl-block {
  margin-bottom: 1vmax;
}

@mixin lvl-block() {
  @extend .lvl-block;
}

// Layout helpers
// NTS: dont make many or too complicated
.h-layout-container-narrow { // One third of available width
  @include laptop { max-width: 33%; }

}


// TEMP
.h-button-text-anchor {
  all: unset;
  text-decoration: underline;
}

.h-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.h-flex-align-center {
  align-items: center;
}

.h-column-layout-inside {
  margin: -1vmax;
}

.h-text-align-right {
  text-align: right;
}

// Whitespace helpers
.h-margin-left-one-unit {
  margin-left: calc(var(--one-unit);
}

//
.h-text-warning {
  color: $color-red;
}


