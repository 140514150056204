.lvl-sidebar {
  background: #343e44;
  align-items: stretch;
  border-right: 21x solid rgba(0,0,0,.12);
  padding-bottom: calc(var(--one-unit) * 2);

  ul {
    li {
      a {
        color: white;
        text-decoration: none;
        padding: calc(var(--one-unit) * .5) calc(var(--one-unit) * 1.5);
        display: block;

        &:hover {
          background: lighten(#343e44, 4%);
        }
      }
      .lvl-label {
        padding: calc(var(--one-unit) * 1.5) calc(var(--one-unit) * 1.5) calc(var(--one-unit) * .3);
        color: rgba(255,255,255,.8);
      }
    }
  }

  .--active {
    background: lighten(#343e44, 4%);
  }
}

.lvl-nav {
  display: flex;
  background: $color-gray-2;
  ul {
    display: flex;
    li {
      a {
        background: $color-gray-2;
        text-decoration: none;
        display: block;
        padding: calc(var(--one-unit) * 1) calc(var(--one-unit) * 1.5);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .lvl-label { display: none; } // temp

  .--active {
      text-decoration: underline;
  }
}
