.lvl-dropdown-container {
  position: relative;
  display: inline-block;


  &:hover {
    cursor: pointer;
  }

  &__label {
    padding: ($one-unit * .3) ($one-unit * 1.5) ($one-unit * .3) ($one-unit * 0.5);
    border: 1px solid $black;
    border-radius: $one-unit * 0.2;

    &:after {
      content: "\2193";
      position: absolute;
      top: $one-unit * .4;
      right: $one-unit * .5;
    }


  }
}

.lvl-dropdown {

  display: inline-block;
  border-radius: $one-unit * 0.2;
  overflow: hidden;
  position: absolute;




  li {
    background: $color-gray-1;
    display: none;
    padding: ($one-unit * .3) ($one-unit * 1.5) ($one-unit * .3) ($one-unit * 0.5);

    &:before {
      content: "\2713";
      opacity: 0;
      margin-right: $one-unit * .25;
    }

    &:hover {
      background: $color-gray-2;
    }

    &.is-displayed {
      display: block;

      &:before {
        // opacity: 1;
      }
    }

    &.is-selected {
      &:before {
        opacity: 1;
      }
    }


  }

  &.is-open {
    li {
      display: block;
    }
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  }




}
