
//////////////////////////////////
// Page (subpage/datapage)
//////////////////////////////////
body {
  // background: #f5f5f6;
}
.lvl-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &__preheader {
    padding: 0.8vmax 3vmax;
    background: $color-gray-1;
    border-bottom: 1px solid #ccc;
    margin-bottom: -1vmax;
  }

  &__header {
    margin-left: 3vmax;
    padding: 2vmax 3vmax 1vmax 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    h1 { margin: 0; }
  }

  &__body {
    padding: 2vmax 3vmax 4vmax;

    > div {
      margin-bottom: 2vmax;
    }
  }

}
