// "Small tables"
.lvl-table-definition-list {
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;

  tr:not(:last-child) {
    border-bottom: 3px solid rgba(255,255,255,0.4);
  }

  td, th {
    padding: ($one-unit * 1) ($one-unit * 1.6) ($one-unit * 1) ($one-unit * 1.5); // Optical difference for left and right side
  }

  th {
    background: $color-gray-2;
    text-transform: uppercase;
    font-size: 80%;
    letter-spacing: $one-unit * 0.08;
  }

  td {
    background: $color-gray-1;
  }

  &--positive {
    th {
      background: lighten($color-green-light, 2%);
    }

    td {
      background: lighten($color-green-light, 8%);
    }
  }

  &--info {
    th {
      background: lighten($color-yellow-light, 2%);
    }

    td {
      background: lighten($color-yellow-light, 8%);
    }
  }

}
