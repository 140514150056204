$one-unit: .8vmax; // legacy - to be removed

////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////

:root {
  --one-unit: .8vmax; // defines padding, white spaces etc
}

@media (max-width: 500px) { // temp phones
  :root {
    --one-unit: 1.4vmax;
  }
}
