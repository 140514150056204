//////////////////////////////////
// Inputs
//////////////////////////////////
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
textarea {
  background: $color-bg-temp;
  padding: ($one-unit * 0.25) ($one-unit * 0.5);
  border-bottom: 2px solid lighten($color-primary, 30%);
  border-radius: 2px 2px 0 0;

  &:focus {
    background: red;
    border-bottom: 2px solid $color-primary;
    background: lighten($color-bg-temp, 5%);
  }
}


//////////////////////////////////
// Base form styles
//////////////////////////////////
form {
  display: flex;
  flex-direction: column;

  // Margin bottom
  > * { margin-bottom: 1vmax; }
  fieldset > * { margin-bottom: 1vmax; }
  fieldset { padding-top: 1vmax; & + fieldset { padding-top: 0; }}
}

label {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.07vmax;
  font-size: 70%;
  color: #3f4a4f;
}

//////////////////////////////////
// Alternativ form styles
//////////////////////////////////
// Custom checkbox - based on: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
/* Customize the label (the container) */
.lvl-input-checkbox {
  display: inline-block;
  position: relative;
  padding-left: $one-unit * 2.1;
  cursor: pointer;
  user-select: none;
  line-height: $one-unit * 1.7;

  input { /* Hide the browser's default checkbox */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark { /* When the checkbox is checked, add a blue background */
      background-color: $color-primary;

      &:after { /* Show the checkmark when checked */
        display: block;
      }
    }

  }

  .checkmark { /* Create a custom checkbox */
    position: absolute;
    top: 0;
    left: 0;
    height: $one-unit * 1.5;
    width: $one-unit * 1.5;
    background-color: $color-gray-4;
    border-radius: 2px;
    overflow: hidden;

    &:after { /* Create the checkmark/indicator (hidden when not checked) */
      content: "";
      position: absolute;
      display: none;
    }

    &:after { /* Style the checkmark/indicator */
      left: $one-unit * .5;
      top: $one-unit * .15;
      width: $one-unit * .5;
      height: $one-unit * 1;
      border: solid white;
      border-width: 0 ($one-unit * .2) ($one-unit * .2) 0;
      transform: rotate(45deg);
    }

  }

  &:hover {
    input:not(:checked) ~ .checkmark { /* On mouse-over, add a grey background color */
      background-color: #ccc;
    }
  }

}

// Fieldset
fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  border-top: 1px solid;
  border-bottom: 1px solid;

  & + fieldset {
    border-top: none;
  }

}



// Input
.lvl-input {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1vmax;

  .validation-message {
    color: $color-red;
    font-style: italic;

    &:first-of-type {
      margin-top: $one-unit * .15;
    }

    &:before {
      content: "- ";
    }
  }

  label {
    margin-bottom: .25vmax;
  }

  &--type-checkbox {
    flex-direction: row;
    align-items: center;
    > * { margin-right: $one-unit * 0.25; }

    label { all: unset; cursor: pointer; }
  }

  &--select {
    position: relative;
    &::before {
      position: absolute;
      bottom: 0.2vmax; right: 0.4vmax;
      line-height: 1.4;
      content: "\2193";
      display: inline-block;
      pointer-events: none;
    }

    select {
      border-radius: 2px;
      background: $color-bg-temp;
      padding: ($one-unit * .25) ($one-unit * 2) ($one-unit * .25) ($one-unit * .5);
    }

  }

  // Temp error styles
  &.--error {
    input:not(:focus) {
      background: $color-red-light;
      border-bottom: 2px solid $color-red;
    }

  }

}

.lvl-input-inline {
  margin-bottom: 1vmax;
  display: flex;
  align-items: stretch;

  input {
    border-radius: 0 2px 0 0;
  }

  label {
    display: flex;
    align-items: center;
    line-height: 100%;

    background: $color-bg-temp;
    border-bottom: 2px solid lighten($color-primary, 30%);
    padding: ($one-unit * 0.25) ($one-unit * 0.5);
    border-radius: 2px 0 0 0;

  }

  &:focus-within {
    > * {
      border-bottom: 2px solid $color-primary;
      background: lighten($color-bg-temp, 5%);
    }
  }

}

//////////////////////////////////
//
//////////////////////////////////
.lvl-input-description {
  font-size: 80%;
  font-style: italic;
  margin-top: $one-unit * 0.3;
}
