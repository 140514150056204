/////////////////////////
// Settings
/////////////////////////
// We want backgrounds in print for now
* {
  print-color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
}

nav { display: none !important; }

/////////////////////////
// Styles
/////////////////////////
.app__header { display: none; }
.lvl-sidebar { display: none; }
.lvl-user-message { display: none; }

.h-no-print {
  display: none;
}


