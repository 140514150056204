
//
.lvl-options-bar {
  display: flex;
  > *:not(:last-child) { margin-right: $one-unit * 1.5; }

  &__header {
    display: flex;
    align-items: center;
    > *:not(:last-child) { margin-right: $one-unit; }
  }

  &__body {
    display: flex;
  }

}
