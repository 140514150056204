//////////////////////////////////
// Colors - Base
//////////////////////////////////
$black: #000000;
$white: #ffffff;

$color-black: $black;
$color-white: $white;

// UX Colors
$color-green: #bcddab;
$color-red: #E25F4A; // Same as Main red
$color-yellow: #f9d19d;

// Profile colors
$color-primary: #343E44; // Main dark blue
$color-secondary: #E25F4A; // Main red

// LVL Colors
$color-green-light: #d4f3c6;
$color-yellow-light: #f6e2c5;
$color-purple-light: #e3c3f1;
$color-blue-light: #c9f0f5;

// Temp extra
$color-red-light: lighten($color-red, 20%);

// Light Gray shades - higher number = darker
$color-gray-1: lighten($color-primary, 72%);
$color-gray-2: lighten($color-primary, 68%);
$color-gray-3: #ebeced;
$color-gray-4: #e4e8ea;

//////////////////////////////////
// TEMP / WIP
//////////////////////////////////
$color-bg-temp: #d7dde0;
