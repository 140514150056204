/* Temp readme

**Styling specific page**
- Add an index.scss in the react folder and style there


*/

//////////////////////////////////
// Imports
//////////////////////////////////

// Reset
@import 'global-styles/reset.scss';

// Settings
@import 'global-styles/variables.scss';
@import 'global-styles/colors.scss';
@import 'global-styles/media-queries.scss';
@import 'global-styles/helpers.scss';

// UX
@import 'global-styles/transitions.scss';

// Global components
@import 'global-styles/forms.scss';
@import 'global-styles/globals.scss';
@import 'global-styles/typography.scss';
@import 'global-styles/buttons.scss';
@import 'global-styles/tables.scss';
@import 'global-styles/callouts.scss';
@import 'global-styles/lists.scss';
@import 'global-styles/dropdowns.scss';
@import 'global-styles/user-notifications.scss';
@import 'global-styles/modal.scss';

//
@import 'global-styles/options-bar.scss';
@import 'global-styles/graphs.scss';

// Structural
@import 'global-styles/pages.scss';

// React Components
@import './components/misc-ui-components/labels/index.scss';
@import './components/misc-ui-components/progress-indicators/index.scss';
@import './components/misc-ui-components/info-card/index.scss';
@import './components/misc-ui-components/provider-excerpt/index.scss';
@import 'global-styles/selector-list.scss';

// Project Components
@import './components/table/index.scss';
@import './components/top-bar/index.scss';
@import './components/sidebar/index.scss';

// Pages
@import './pages/ba-team/index.scss';
@import './pages/ba-edit/index.scss';
@import './pages/ba-invoices-details/index.scss';

// Print
@import 'global-styles/print.scss';


//////////////////////////////////
// WIP
//////////////////////////////////
.lvl-label {
  text-transform: uppercase;
  font-size: 60%;
  opacity: 0.4;
  font-weight: bold;
  letter-spacing: 0.06vmax;
}

.lvl-text-style-2 {
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: bold;
  letter-spacing: 0.06vmax;
}

.lvl-visual-log {
  $graph-height: 5vmax;

  &__graph {
    z-index: 2;
    display: flex;
    height: $graph-height;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 0.5vmax;
    position: relative;

    &:before {
      z-index: -1;
      content: "No data...";
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: $graph-height;
      text-align: center;
      background: #ccc;
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;

    > * {
      flex: 1 1 33%;
      font-size: 70%;
      font-weight: bold;
      letter-spacing: 0.07vmax;
      text-transform: uppercase;
      opacity: 0.8;
    }

    > *:last-child { text-align: right }
    > *:nth-child(2) { text-align: center; }

  }

}
