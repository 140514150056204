.lvl-invoice {
  position: relative;

  @media screen {
    border: 2px solid black;
    border-radius: $one-unit * .25;
    padding: $one-unit * 4;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__cant-show-message {
    display: none;
  }

  &__client-details {
    margin-bottom: 20px;
  }

  &__invoice-details {
    margin-bottom: 40px;
  }

  .lvl-logo { margin-bottom: 20px; }

}

@include phone-portrait {
  .lvl-invoice {

    &__cant-show-message {
      display: block;
    }

    > *:not(.lvl-invoice__cant-show-message) {
      display: none;
    }
  }
}
