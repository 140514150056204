.lvl-selector-list {
  display: flex;
  flex-direction: column;

  a {
    border: 1px solid $color-primary;
    border-radius: $one-unit * 0.2;
    padding: $one-unit * 2;
    margin-bottom: $one-unit;
    text-decoration: none;
    @include lvl-transition($property: background);

    &:hover {
      background: $color-gray-1;
      @include lvl-transition($property: background);
      text-decoration: underline;
    }

  }

}
