// payment method content
.lvl-payment-method {
  display: flex;
  padding-bottom: $one-unit * 3;

  &__header {
    margin-right:  $one-unit * 3;
  }

  &__icon * {
    font-size: 500%; // temp
  }

}
