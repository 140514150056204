
//////////////////////////////////
// Globals
//////////////////////////////////


a {
  color: black;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

small {
  font-size: 80%;
}

::selection { background: $color-primary; color: white; }

.lvl-cta-help-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  text-align: center;
  background: $color-green;
  > * { margin-bottom: 0; padding: 0.6vmax;}
}

.levelup-badge {
  display: block;
  width: 80px;
  .st1, .st2 { fill: $color-primary }
}
