// Callout
.lvl-callout {
  background: #f9f9f9;
  padding: 2vmax;
  border: 2px solid $color-primary;
  border-radius: 0.2vmax;

  // Colors
  &--danger {
    border-color: $color-red;
    &.-emphasized-header { > h2 { background: $color-red !important; } }
  }

  &--info {
    border-color: $color-yellow;
    &.-emphasized-header { > h2 { background: $color-yellow !important; } }
  }

  &--positive {
    border-color: $color-green;
    &.-emphasized-header { > h2 { background: $color-green !important; } }
  }

  // types
  &--print {
    border: none;
    padding: 40px;
  }

  // Modifiers
  &.-emphasized-header {
    padding: 0;
    > h2 {
      padding: 1vmax 2vmax;
      background: $color-primary;
      margin-bottom: 0;
      color: $white;
    }
    > div {
      padding: 2vmax;
    }
  }

  @include lvl-block;

}
