// Vertical viewport
@mixin mq-vertical-viewport() {
  @media (orientation: portrait) and (max-width: 500px) {
    @content;
  }
}

// Settings
$viewport-medium: 640;
$viewport-large: 1400;
$viewport-xlarge: 1921; // 1921

/*****************************
Base queries
******************************/
@mixin tablet {
  @media (min-width: #{$viewport-medium}px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$viewport-large}px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$viewport-xlarge}px) {
    @content;
  }
}

/*****************************
Special queries
******************************/
@mixin phone-portrait {
  @media screen and (max-width: #{$viewport-large}px) and (orientation: portrait) {
    @content;
  }
}

// Smartphones, touchscreens
@media (hover: none) and (pointer: coarse) {
    /* ... */
}
