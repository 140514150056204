.lvl-status-label {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.07vmax;
  font-size: 70%;
  background: $color-primary;
  padding: 0.1vmax 0.4vmax;
  color: $white;
  border-radius: 500px;
  white-space: nowrap;

  &--success {
    background: $color-green;
  }

  &--error {
    background: $color-red;
  }

  &--warning {
    background: $color-yellow;
  }

}
