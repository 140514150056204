
.lvl-graph {
  &__datapoint {

    flex: 1 1 auto;

    &--success {
      background: $color-green;
    }

    &--error {
      background: $color-red;
    }

  }

}
