@mixin lvl-button-hover-effect($color) {
  background: darken($color, 6%);
  @include lvl-transition($property: background);
}

.lvl-button {
  border-radius: 3px;
  padding: ($one-unit * 0.5) ($one-unit * 0.75);
  background: $color-yellow-light;
  text-decoration: none;
  @include h-is-clickable;
  white-space: nowrap;
  display: inline-block;
  @include lvl-transition($property: background);

  // States
  &:hover {
    @include lvl-button-hover-effect($color: $color-yellow-light);
  }

  // Styles
  &--dangerous {
    background: $color-red-light;
    &:hover { @include lvl-button-hover-effect($color: $color-red-light); }
  }

  &--proceed {
    background: $color-green-light;
    &:hover { @include lvl-button-hover-effect($color: $color-green-light); }
  }

  // Types
  &--hollow {
    text-decoration: underline;
    background: none;
    &:hover { @include lvl-button-hover-effect($color: #eee); text-decoration: none; }
  }

  // Sizes
  &--small {
    font-size: 70%;
    padding: ($one-unit * 0.25) ($one-unit * 0.5);
  }

  &--large {
    font-size: 125%;
    padding: ($one-unit * 0.75) ($one-unit * 1);
  }

  // Icon handling
  .fa {
    margin-right: $one-unit * .5;
  }

}


button {
  @extend .lvl-button;

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

// .lvl-button-group { // temp legacy
//   > * {
//     margin-right: 0.3vmax;

//     &:last-child {
//       margin-right: 0;
//     }
//   }

// }

// "Autogroup"
.lvl-button + .lvl-button {
  margin-left: 0.3vmax;
}
