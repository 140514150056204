.lvl-provider-excerpt {
  display: flex;
  align-items: center;

  &__logo {
    max-width: 150px;
    margin-left: 0.5vmax;
  }

  .levelup-badge { // temp
    width: 150px;
  }
}
