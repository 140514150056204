// @keyframes modal-animate-in {
//   from { width: 0; }
//   to { width: auto; }
// }

// @keyframes modal-animate-out {
//   from { width: auto; }
//   to { width: 0; }
// }

.lvl-modal {
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  overflow: hidden;
  background: $white;

  box-shadow: 0 8px 6px -6px rgba(black, 0.3);

  animation-name: modal-animate-in;
  animation-duration: 0.8s;
  // animation-timing-function: cubic-bezier(.25,.1,.25,1);

  &.is-closing {
    animation-name: modal-animate-out;
    animation-duration: 0.2s;
    // animation-timing-function: cubic-bezier(.25,.1,.25,1);
  }

  &__content {
    padding: $one-unit * 3;
    border: .3vmax solid lighten($color-primary, 20%);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: $one-unit;
    right: $one-unit;
    padding: 0 (($one-unit * 0.5) - ($one-unit * 0.25)) 0 ($one-unit * 0.5); // Subtract the border width

    &:after {
      content: "\f00d";
      font-family: "Font Awesome 5 Pro";
      font-size: calc(var(--one-unit) * 1.5);
      font-weight: bold;
      color: $color-primary;
    }
  }

}
