.lvl-top-bar {
  background: #343e44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--one-unit) * 1.25) calc(var(--one-unit) * 1.5);

  * { color: white; }

  &__header {
    display: flex;

    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgba(white,0.2);
      padding-right: calc(var(--one-unit) * 2);
      margin-right: calc(var(--one-unit) * 2);

      &:last-child {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
      }
    }

  }

  &__body {
    display: flex;
    justify-content: space-between;

    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgba(white,0.2);
      padding-right: calc(var(--one-unit) * 2);
      margin-right: calc(var(--one-unit) * 2);

      &:last-child {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
      }

    }

  }

  svg {
    width: 10vmax;

    .st1 {
      fill: white !important;
    }
  }

}

.user-excerpt {
  display: flex;
  align-items: center;

  &__username {
    margin-right: $one-unit;
  }

  &__image {
    border-radius: 500px;
    overflow: hidden;
    width: $one-unit * 3;
  }

}


.client-picker {
  position: relative;

  &__field {
    &:hover {
      cursor: pointer;
    }
  }

}

.user-nav {
  position: relative;
}

.dropdown-menu {
  background: white;
  border: 1px solid #ccc;
  border-radius: 3px;

  position: absolute;
  top: 2.9vmax;
  right: 0;
  box-shadow: rgba(128, 130, 133, 0.3) 0px 8px 20px;

  hr {
    border: none;
    border-bottom: 1px dashed #ccc;
    margin: 0 1.2vmax;
  }

  a {
    padding: 0.6vmax 1.2vmax;
    display: block;
    white-space: nowrap;
    color: black;
    text-decoration: none;

    &:hover {
      background: $color-gray-1;
    }

  }

  &__search {
    padding: 0.3vmax 1.2vmax 0.6vmax;
    display: flex;

    input { margin-bottom: 0; }
  }


  &__label {
    padding: 0.6vmax 1.2vmax 0.3vmax;
    display: inline-block;
    color: $color-primary;
  }

  &--left-aligned {
    right: auto;
    left: 0;
  }

}
