
// Selectable list
.lvl-selectable-list {
  display: flex;
  li {

    border-bottom: 2px solid transparent;
    @include lvl-transition($property: border-bottom);

    &:not(:last-child) {
      margin-right: 1vmax;
    }

    &:hover {
      cursor: pointer;
      border-bottom: 2px solid rgba($black, 0.2);
      @include lvl-transition($property: border-bottom);
    }

    &.-selected {
      border-bottom: 2px solid $black;
      @include lvl-transition($property: border-bottom);
    }
  }

}
